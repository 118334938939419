import { PageProps } from 'gatsby';
import { FC } from 'react';
import { ContentSection, Footnotes, H3, Hero, Layout } from '~/components';
import { Frontmatter } from '~/config';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Library: Footnotes',
  orderInLib: 30,
  screenShoot: false,
};

const Page: FC<PageProps> = (props) => {
  return (
    <Layout>
      {/* hero: block for menu */}
      <Hero />
      <ContentSection>
        <H3 normalCase>Footnotes with Abreviations</H3>
        <Footnotes
          footnotes={[
            'randomized',
            'recurrence',
            'absolute-benefit',
            'category-2a',
            'no-grade-4',
            'doses',
          ]}
          references={[1, 1, '1,2', '3,4', 0, 0, 3]}
          abreviations={['ADL', 'CBR', 'CI', 'HR--hazard']}
        />

        <H3 normalCase>Single Footnote (Left) with single Abbreviation</H3>
        <Footnotes
          footnotes="absolute-benefit"
          abreviations="HR--hormone"
          alignment="left"
        />

        <H3 normalCase>Footnotes (Right)</H3>
        <Footnotes
          footnotes={['randomized', 'recurrence', 'absolute-benefit']}
          alignment="right"
        />

        <H3 normalCase>Abreviations Only</H3>
        <Footnotes abreviations={['ADL', 'CBR', 'CI', 'HR--hazard']} />
      </ContentSection>
    </Layout>
  );
};

export default Page;
